import React, { useState } from "react";
import { ListGroup, Accordion, Card, Table } from "react-bootstrap";
import { Trans } from "gatsby-plugin-react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import QuickDataTable from "./QuickDataTable";

// FUTURE this data should come from the database one way or another (implement a CMS)
const faqData = [
  {
    section: {
      title: {
        text: "Indicators and Data Sets",
        transKey: "dataSetsTitle",
      },
      faqs: [
        {
          question: {
            text: "What are our indicators and datasets and why were they selected?",
            transKey: "dataSetsQ1",
          },
          answer: {
            text: (
              <>
                <p>Please review the table below:</p>
                <QuickDataTable
                  headers={[
                    {
                      text: "Indicator",
                      transKey: "dataSetsA1-th-1",
                    },
                    {
                      text: "Dataset",
                      transKey: "dataSetsA1-th-2",
                    },
                    {
                      text: "Temporal Availability",
                      transKey: "dataSetsA1-th-3",
                    },
                    {
                      text: "Temporal Resolution",
                      transKey: "dataSetsA1-th-4",
                    },
                    {
                      text: "Purpose",
                      transKey: "dataSetsA1-th-5",
                    },
                  ]}
                  rows={[
                    [
                      {
                        rowSpan: 2,
                        text: "Total Water Change",
                        transKey: "dataSetsA1-tr-1-tc-1",
                      },
                      {
                        text: "GRACE (The Gravity Recovery and Climate Experiment)",
                        transKey: "dataSetsA1-tr-1-tc-2",
                      },
                      {
                        text: "2002-Present",
                        transKey: "dataSetsA1-tr-1-tc-3",
                      },
                      {
                        text: "Monthly",
                        transKey: "dataSetsA1-tr-1-tc-4",
                      },
                      {
                        text: "Measures changes in the Earth’s Mass; derived total water mass",
                        transKey: "dataSetsA1-tr-1-tc-5",
                      },
                    ],
                    [
                      {
                        colSpan: 4,
                        text: "<b>Project relevance:</b> Measuring water abundance or absence over time provides an important baseline for overall water within a region.",
                        transKey: "dataSetsA1-tr-2-tc-1",
                      },
                    ],
                    [
                      {
                        rowSpan: 2,
                        text: "Land Water Change",
                        transKey: "dataSetsA1-tr-3-tc-1",
                      },
                      {
                        text: "GRACE",
                        transKey: "dataSetsA1-tr-3-tc-2",
                      },
                      {
                        text: "2002-Present",
                        transKey: "dataSetsA1-tr-3-tc-3",
                      },
                      {
                        text: "Monthly",
                        transKey: "dataSetsA1-tr-3-tc-4",
                      },
                      {
                        text: "Measures changes in land water levels",
                        transKey: "dataSetsA1-tr-3-tc-5",
                      },
                    ],
                    [
                      {
                        colSpan: 4,
                        text: "<b>Project relevance:</b> Land water change can indicate natural and human activities on Earth.",
                        transKey: "dataSetsA1-tr-4-tc-1",
                      },
                    ],
                    [
                      {
                        rowSpan: 2,
                        text: "Ground Water Change",
                        transKey: "dataSetsA1-tr-5-tc-1",
                      },
                      {
                        text: "GRACE",
                        transKey: "dataSetsA1-tr-5-tc-2",
                      },
                      {
                        text: "2002-Present",
                        transKey: "dataSetsA1-tr-5-tc-3",
                      },
                      {
                        text: "Monthly",
                        transKey: "dataSetsA1-tr-5-tc-4",
                      },
                      {
                        text: "Measures changes in groundwater levels",
                        transKey: "dataSetsA1-tr-5-tc-5",
                      },
                    ],
                    [
                      {
                        colSpan: 4,
                        text: "<b>Project relevance:</b> Measuring groundwater abundance or absence over time provides an important baseline for overall water within a region.",
                        transKey: "dataSetsA1-tr-6-tc-1",
                      },
                    ],
                    [
                      {
                        rowSpan: 2,
                        text: "Precipitation",
                        transKey: "dataSetsA1-tr-7-tc-1",
                      },
                      {
                        text: "CMORPH (Gravity Recovery and Climate Experiment)",
                        transKey: "dataSetsA1-tr-7-tc-2",
                      },
                      {
                        text: "1999-Present",
                        transKey: "dataSetsA1-tr-7-tc-3",
                      },
                      {
                        text: "Daily",
                        transKey: "dataSetsA1-tr-7-tc-4",
                      },
                      {
                        text: "Measures fluctuations in rainfall and potential drought or flooding",
                        transKey: "dataSetsA1-tr-7-tc-5",
                      },
                    ],
                    [
                      {
                        colSpan: 4,
                        text: "<b>Project relevance:</b> Seasonal and long term changes in precipitation influence potable water availability and  agricultural viability.",
                        transKey: "dataSetsA1-tr-8-tc-1",
                      },
                    ],
                    [
                      {
                        rowSpan: 2,
                        text: "Soil Moisture",
                        transKey: "dataSetsA1-tr-9-tc-1",
                      },
                      {
                        text: "SMAP (Soil Moisture Active Passive)",
                        transKey: "dataSetsA1-tr-9-tc-2",
                      },
                      {
                        text: "2015-Present",
                        transKey: "dataSetsA1-tr-9-tc-3",
                      },
                      {
                        text: "Daily",
                        transKey: "dataSetsA1-tr-9-tc-4",
                      },
                      {
                        text: "Measures soil water levels and fertility",
                        transKey: "dataSetsA1-tr-9-tc-5",
                      },
                    ],
                    [
                      {
                        colSpan: 4,
                        text: "<b>Project relevance:</b> Soil moisture estimates are important for monitoring droughts, predicting floods, and assisting agriculture.",
                        transKey: "dataSetsA1-tr-10-tc-1",
                      },
                    ],
                    [
                      {
                        rowSpan: 2,
                        text: "Surface Water Extent",
                        transKey: "dataSetsA1-tr-11-tc-1",
                      },
                      {
                        text: "GSWE",
                        transKey: "dataSetsA1-tr-11-tc-2",
                      },
                      {
                        text: "1999-2020",
                        transKey: "dataSetsA1-tr-11-tc-3",
                      },
                      {
                        text: "Annual",
                        transKey: "dataSetsA1-tr-11-tc-4",
                      },
                      {
                        text: "Measures changes in total surface water",
                        transKey: "dataSetsA1-tr-11-tc-5",
                      },
                    ],
                    [
                      {
                        colSpan: 4,
                        text: "<b>Project relevance:</b> This dataset measures the dynamics of terrestrial surface-water features such as ponds, rivers, and wetlands, which is valuable for the purposes of land use, water management, and ecosystems and services.",
                        transKey: "dataSetsA1-tr-12-tc-1",
                      },
                    ],
                    [
                      {
                        rowSpan: 2,
                        text: "Vegetation",
                        transKey: "dataSetsA1-tr-13-tc-1",
                      },
                      {
                        text: "NOAA CDR & NDVI (Normalized Difference Vegetation Index)",
                        transKey: "dataSetsA1-tr-13-tc-2",
                      },
                      {
                        text: "1981-Present (updated every 10 days)",
                        transKey: "dataSetsA1-tr-13-tc-3",
                      },
                      {
                        text: "Daily",
                        transKey: "dataSetsA1-tr-13-tc-4",
                      },
                      {
                        text: "Measures vegetation change and classifies pixels by crop-type",
                        transKey: "dataSetsA1-tr-13-tc-5",
                      },
                    ],
                    [
                      {
                        colSpan: 4,
                        text: "<b>Project relevance:</b> Land cover change to support agriculture increases water demand. The degree of this demand is determined by irrigation versus precipitation dependency during cultivation. Although agriculture may support development and therefore contribute to stability, it may conversely increase water insecurity.",
                        transKey: "dataSetsA1-tr-14-tc-1",
                      },
                    ],
                    [
                      {
                        colSpan: 4,
                        text: "<b>Project relevance:</b> Changing population density and distribution impact and are impacted by water availability.",
                        transKey: "dataSetsA1-tr-16-tc-1",
                      },
                    ],
                  ]}
                  as={Table}
                  tableAttributes={{
                    className: "faq-table",
                    striped: "true",
                    bordered: "true",
                  }}
                />
              </>
            ),
          },
        },
        {
          question: {
            text: "How did we acquire our datasets?",
            transKey: "dataSetsQ2",
          },
          answer: {
            text: "Our project leverages environmentally proposed remote sensing data from over 20 satellites. This open-source data is collected from international satellite missions ranging from Landsat to Sentinel. Data acquisition was led by our commercial geospatial partners at Element 84.",
            transKey: "dataSetsA2",
          },
        },
        {
          question: {
            text: "How do we determine demand versus supply indicators?",
            transKey: "dataSetsQ3",
          },
          answer: {
            text: "Factors that contribute to water availability are classified as supply indicators, while those that strain water quality or quantity are classified as indicators of water demand.",
            transKey: "dataSetsA3",
          },
        },
        {
          question: {
            text: "Do we classify land used for agriculture as a demand or supply indicator?",
            transKey: "dataSetsQ4",
          },
          answer: {
            text: "Because agriculture is a water-intensive sector, it is classified as a water demand indicator. Out of the range of land cover classifications pertaining to vegetation, we selected a classification that most closely approximates crop presence. This was chosen because land use for agriculture relative to land use for grasslands or shrubbery presents a greater water demand.",
            transKey: "dataSetsA4",
          },
        },
        {
          question: {
            text: "How should a user interpret the Vegetation indicator?",
            transKey: "dataSetsQ5",
          },
          answer: {
            text: "The NDVI classifies land into 24 land-cover types.",
            transKey: "dataSetsA5",
          },
        },
        {
          question: {
            text: "Where do we source our NDVI data from and what specific corrections are applied to it?",
            transKey: "dataSetsQ6",
          },
          answer: {
            text: "We source our NDVI data from https://www.ncei.noaa.gov/pub/data/sds/cdr/CDRs/Normalized%20Difference%20Vegetation%20Index/AlgorithmDescription_01B-20b.pdf. The corrections applied to the NOAA CDR NDVI product are: cloud masking; geometry-based shadow masking; atmospheric correction, which uses water vapor, digital elevation model, ozon, stratospheric aerosol climatology, and tropospheric aerosol corrections; and BRDF correction.",
            transKey: "dataSetsA6",
          },
        },
      ],
    },
  },
  {
    section: {
      title: {
        text: "Data Processing, Calculations, and Standards",
        transKey: "dataProcessingTitle",
      },
      faqs: [
        {
          question: {
            text: "How do we process the data?",
            transKey: "dataProcessingQ1",
          },
          answer: {
            text: (
              <div>
                <ol>
                  <li>
                    <Trans i18nKey="dataProcessingA1-1">
                      Download data from open-source satellites
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="dataProcessingA1-2">
                      Normalize data to produce monthly values for each
                      indicator
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="dataProcessingA1-3">
                      Generate a historical average for each month for each
                      region
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="dataProcessingA1-4">
                      Calculate variance values for each month for each region
                      based on a raw value’s variance from the historical
                      average
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="dataProcessingA1-5">
                      Aggregate all indicator values to master geodatabase
                    </Trans>
                  </li>
                </ol>
              </div>
            ),
            transKey: "dataProcessingA1",
          },
        },
        {
          question: {
            text: "Why do we aggregate all values by the month and what is data normalization?",
            transKey: "dataProcessingQ2",
          },
          answer: {
            text: "Normalization is the process of creating a common denominator from which different datasets can be compared. Each dataset has a unique temporal range, temporal resolution, and spatial resolution; i.e. because some data is collected by satellites daily, while others annually, we average all values across the common denominator of a month. This creates a datapoint for every month-year combination for each dataset so that indicators may be cross-compared more effectively.",
            transKey: "dataProcessingA2",
          },
        },
        {
          question: {
            text: "What is historical average and how is it calculated?",
            transKey: "dataProcessingQ3",
          },
          answer: {
            text: "Water availability fluctuates throughout the year, according to natural seasonal cycles. In order to understand when water is abnormally low or high, we calculated historical average values for each month in each region. For example, all of the Julys in the Ninawa governorate can be compared to each other to identify years of particular water abundance or scarcity.  The historical average provides a baseline approximation for what is considered normal or expected for a given month.",
            transKey: "dataProcessingA3",
          },
        },
        {
          question: {
            text: "What is historical variance and how is it calculated?",
            transKey: "dataProcessingQ4",
          },
          answer: {
            text: "Each raw datapoint is compared to the historical average value for that month and region. The resulting value is the historical variance and is a percent ranging from -1 to 1, indicating decreases or increases from the expected baseline value for that month. Data on the dashboard is presented in historical variance format rather than in raw value format to generate a standard axis on which indicators measured in different units can be cross-compared and easily understood. If we did not do this, the specific units of each indicator would be difficult to compare, e.g. cubic centimeters to total water storage difference. The historical variance calculation solves this.",
            transKey: "dataProcessingA4",
          },
        },
        {
          question: {
            text: "What is “% Variance” and how is it calculated?",
            transKey: "dataProcessingQ5",
          },
          answer: {
            text: "Percent Variance is how we display variances from historical averages. This allows the viewer to efficiently analyze the significance of fluctuations over time. This scalar model reflects the magnitude of change and provides a standard baseline of analysis in which all indicators may be graphed simultaneously. This allows the end-user to compare indicators against each other, despite their differing units and resolutions. Additionally, it presents the data in a more digestible way for end-users who are not experts of each indicator. For example, a 7mm increase in precipitation per year may hold little meaning to users who are policy makers, not hydrologists.",
            transKey: "dataProcessingA5",
          },
        },
        {
          question: {
            text: "What kind of data standards are applied?",
            transKey: "dataProcessingQ6",
          },
          answer: {
            text: "Prior to selecting datasets, a literature review is conducted by our research partners at Stanford and the UN. We vigorously analyze data sets and their integrity through an extensive process to validate its usefulness, given our intended purpose. There are a few standard corrections applied to satellite datasets such as cloud coverage and BRDF correction. These are proven scientific models that identify or correct for pesky cloud coverage and light reflectance that obscure satellite sensors and imagery.",
            transKey: "dataProcessingA6",
          },
        },
        {
          question: {
            text: "Who is the map provider?",
            transKey: "dataProcessingQ7",
          },
          answer: {
            text: "The map used in the Water Security board is generated by Mapbox using their basemap and the GADM political boundaries.",
            transKey: "dataProcessingA7",
          },
        },
      ],
    },
  },
];

const FaqAccordion = () => {
  const [activeKey, setActiveKey] = useState(null);

  const toggleActiveKey = (newKey) => {
    if (activeKey === newKey) {
      setActiveKey(null);
    } else {
      setActiveKey(newKey);
    }
  };

  return (
    <>
      <ListGroup as="ul">
        {faqData.map(({ section }) => (
          <ListGroup.Item key={section.title.transKey} as="li">
            <h5 className="mb-1 cursor-default">
              <Trans i18nKey={section.title.transKey}>
                {section.title.text}
              </Trans>
            </h5>
            {!!section.description && section.description.text && (
              <p className="mb-1 cursor-default">
                <Trans i18nKey={section.description.transKey}>
                  {section.description.text}
                </Trans>
              </p>
            )}
            <Accordion>
              {section.faqs.map(
                ({ question, answer }, index) =>
                  question &&
                  answer && (
                    <Card key={index}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={question.transKey}
                        className="d-flex justify-content-between align-items-center"
                        onClick={() => toggleActiveKey(question.transKey)}
                      >
                        <Trans i18nKey={question.transKey}>
                          {question.text}
                        </Trans>
                        {activeKey === question.transKey ? (
                          <FontAwesomeIcon icon={faChevronUp} />
                        ) : (
                          <FontAwesomeIcon icon={faChevronDown} />
                        )}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={question.transKey}>
                        <Card.Body>
                          {answer.transKey ? (
                            <Trans i18nKey={answer.transKey}>
                              {answer.text}
                            </Trans>
                          ) : (
                            answer.text
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
              )}
            </Accordion>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

export default FaqAccordion;

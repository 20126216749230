const addConflictLayer = (map, conflictData) => {
  if (!map) return;
  if (map.getLayer("conflict-label")) {
    map.removeLayer("conflict-label");
  }
  if (map.getLayer("conflict-layer")) {
    map.removeLayer("conflict-layer");
  }
  if (map.getSource("conflict-data")) {
    map.removeSource("conflict-data");
  }

  if (conflictData) {
    // add conflict layer
    map.addSource("conflict-data", {
      type: "geojson",
      data: conflictData,
      cluster: true,
      clusterMaxZoom: 18,
      clusterRadius: 25,
      clusterProperties: {
        id: ["max", ["get", "id"]],
        sumTotals: ["+", ["get", "ref_value"]],
        minDateRangeStart: ["min", ["get", "unix_timestamp_start_date"]],
        maxDateRangeEnd: ["max", ["get", "unix_timestamp_end_date"]],
      },
    });

    map.addLayer(
      {
        id: "conflict-layer",
        source: "conflict-data",
        type: "circle",
        paint: {
          "circle-stroke-width": 1,
          "circle-stroke-color": "#FFF",
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#000",
            2,
            "#000",
            6,
            "#000",
            15,
            "#000",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            6,
            2,
            8,
            6,
            12,
            15,
            19,
          ],
        },
      },
      "poi-label"
    );

    map.addLayer({
      id: "conflict-label",
      type: "symbol",
      source: "conflict-data",
      layout: {
        "text-field": ["get", "point_count_abbreviated"],
        "text-size": 12,
      },
      paint: {
        "text-color": "#fff",
      },
    });
  }
};

export default addConflictLayer;

import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { ListGroup, Button, OverlayTrigger, Badge } from "react-bootstrap";

import formatDate from "./helpers/formatDate";
import ProductInfoPopUp from "./ProductInfoPopup";

const AccordionProductListItem = ({
  product,
  active,
  selectionCallback,
  disabled,
  willExceedMaxSelection,
}) => (
  <OverlayTrigger
    trigger={["hover", "focus"]}
    placement="right"
    overlay={ProductInfoPopUp(product)}
  >
    {({ ref, ...triggerHandler }) => (
      <ListGroup.Item
        key={product.name}
        ref={ref}
        className={`list-group-item ${
          disabled || willExceedMaxSelection ? "disabled" : ""
        }`}
      >
        <div className="card-text-content">
          <div>
            <h4 className="label" aria-describedby="info-tooltip">
              <Trans>{product.label}</Trans>
            </h4>
            {product.description && (
              <span {...triggerHandler}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  role="tooltip"
                  id="info-tooltip"
                />
              </span>
            )}
          </div>
          {product.date_min && (
            <small className="date-range">
              {formatDate(product.date_min)} - {formatDate(product.date_max)}
            </small>
          )}
          {disabled && <Badge className="unavailable-badge"><Trans>Unavailable</Trans></Badge>}
        </div>
        <Button
          className={`card-selection-button${active ? " active" : ""} ${
            disabled || willExceedMaxSelection ? "disabled" : ""
          }`}
          onClick={(e) => !disabled && selectionCallback(e)}
          id={product.name}
        >
          <FontAwesomeIcon icon={active ? faEye : faEyeSlash} />
        </Button>
      </ListGroup.Item>
    )}
  </OverlayTrigger>
);

export default AccordionProductListItem;

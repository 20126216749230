import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import HeaderMainLogo from "./HeaderMainLogo";
import LanguageSelector from "./LanguageSelector";
import GeoGuardLogo from "../assets/images/geoguard-logo@2x.png";

/**
 * shows the header component
 * @returns {any}
 */
const MobileWarning = () => {
  return (
    <div class="mobile-warning">
        <div className="mobile-warning__language-switcher">
            <LanguageSelector
                className="map-nav-dropdown-link"
                showIcon={false}
            />
        </div>

        <HeaderMainLogo href="#" logo={<img src={GeoGuardLogo} alt="Geoguard Logo" />} />

        <p className="section-content">
            <Trans i18nKey="mobileWarning">
                Geoguard was designed for tablet and desktop use. Please visit on a larger device for the best experience.
            </Trans>
        </p>
    </div>
  );
};

export default MobileWarning;

import React, { useEffect, useState } from "react";
import CrafdLogo from "../assets/images/CRAFd-DPPA-logo.svg";
import { Modal, Button } from "react-bootstrap";
import { Trans } from "gatsby-plugin-react-i18next";

import { userDisclaimerAffirmationRequest } from "../actions";
import LanguageSelector from "./LanguageSelector";

const UserAffirmationModal = ({ dispatch, affirm }) => {
  const [affirmState, setAffirmState] = useState(affirm);

  const handleAccept = () => {
    setAffirmState(true);
  };

  // dispatches affirmation status to redux state as a side effect of setting the component state
  useEffect(() => {
    dispatch(userDisclaimerAffirmationRequest(affirmState));
  }, [dispatch, affirmState]);

  return (
    <Modal
      show={!affirmState}
      onHide={handleAccept}
      scrollable={true}
      backdrop="static"
      centered
      className="affirmation-modal"
    >
      <Modal.Body>
        <div className="welcome-modal">
          <div className="welcome-container">
            <p className="welcome-title">
              <Trans>Welcome to Geoguard</Trans>
            </p>
            {/* TODO: Update the description copy when we have it */}
            {/* <p className="welcome-description">
              One sentence about the purpose of Geoguard, and what users can get
              out of using the tool.
            </p> */}
            <div className="welcome-logo-container">
              <img
                src={CrafdLogo}
                alt="Crafd and DPPA Logo"
                style={{ height: 30 }}
              />
            </div>
          </div>
          <div className="disclaimer-container">
            <div className="section-header disclaimer">
              <Trans>Disclaimer</Trans>
              <div className="section-language">
                <LanguageSelector
                  className="map-nav-dropdown-link"
                  showIcon={false}
                />
              </div>
            </div>
            <p className="section-content">
              <Trans i18nKey="disclaimer1">
                Materials provided on this Site are provided &quot;as is&quot;,
                without warranty of any kind, either express or implied,
                including, without limitation, warranties of merchantability,
                fitness for a particular purpose and non-infringement. The
                United Nations Secretariat specifically does not make any
                warranties or representations as to the accuracy or completeness
                of any such materials. The data sets provided herein are
                gathered from &quot;open-source&quot; providers and have been
                averaged by month for the purpose of using a common scale.
              </Trans>
            </p>
            <p className="section-content">
              <Trans i18nKey="disclaimer2">
                The designations employed and the presentation of material on
                this site do not imply the expression of any opinion whatsoever
                on the part of the Secretariat of the United Nations concerning
                the legal status of any country, territory, city or any area or
                of its authorities, or concerning the delimitation of its
                frontiers or boundaries.
              </Trans>
            </p>
            <div className="section-action">
              <Button variant="primary" onClick={handleAccept}>
                <Trans>{"Accept & Continue"}</Trans>
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserAffirmationModal;

const baseTextStyling = "color: #333; font-family: Roboto; font-weight: 400;";
const normalTextStyling = baseTextStyling + "font-size: 14px;";
const largeTextStyling = baseTextStyling + "font-size: 17px;";
const conflictListStyling =
  "list-style: none; padding: 0; margin-bottom: 0; display: flex; gap: 0.5rem; flex-direction: column;";

// Convert UNIX timestamp to "yyyy-mm-dd" format
const formatDateTimestamp = (d) =>
  d ? new Date(d * 1000).toLocaleDateString("en-US") : "N/A";

// Regional labels determined by administrative level,
// currently have data up to admin level 2, sub-provinces
const getHoverLabelContent = (props) => {
  if (!props["name_1"] && !props["name_2"]) {
    return `<h6 style="${largeTextStyling}">${props["name_0"]}</h6>`;
  }

  let content = props["name_1"];

  if (props["name_2"]) {
    content = "<strong>" + props["name_2"] + "</strong>,&nbsp;" + content;
  }
  if (props["name_0"]) {
    content = content + ", " + "<i>" + props["name_0"] + "</i>&nbsp;";
  }
  return `<h6 style="${largeTextStyling}">${content}</h6>`;
};

const getConflictContentLabel = (props, t) =>
  `<h6 style="${largeTextStyling}">${props.ref_value ?? 0} ${
    props.ref_value !== 1 ? t("Fatalities") : t("Fatality")
  }</h6>`;

const getConflictDateRange = (start, end) =>
  `<li style="${normalTextStyling} color: #6f6f6f;">${formatDateTimestamp(
    start
  )} - ${formatDateTimestamp(end)}</li>`;

const buildPopupHTMLContent = (
  feature,
  conflicts,
  translateFunc,
  conflictData
) => {
  // Handle map hover of individual regions and add label + % increase/descrease
  let content = getHoverLabelContent(feature.properties);

  // Do conflict popup content on hover of conflict clusters
  if (conflicts.length) {
    // conflict data displays using mapbox markers, which cluster together, but
    // still attribute to a country name, which inaccurately shows a huge
    // number of conflicts to specific countries. to overcome this, we'll empty
    // the content variable, to start again without the country name

    content = '';

    const { name: conflictName, unit } = conflictData;
    const isTypeACLED = conflictName.includes("acled");
    const props = conflicts[0].properties;
    let bodyContent = "";
    // Clusters content:
    // number of conflicts, total number of "value" and the dates of events
    if (props.cluster) {
      bodyContent += `<ul style="${conflictListStyling}">`;
      bodyContent += getConflictDateRange(
        props.minDateRangeStart,
        props.maxDateRangeEnd
      );
      bodyContent += `<li style="${normalTextStyling}">${props.point_count} ${
        isTypeACLED ? unit : translateFunc("conflicts")
      }</li>`;
      bodyContent += `<li style="${normalTextStyling}">${
        props.sumTotals
      } ${translateFunc("fatalities")}</li>`;
      bodyContent += "</ul></div>";
    } else {
      // Conflict value first as sub-header
      content += getConflictContentLabel(props, translateFunc);

      // Individual Conflict content:
      // ACLED: Event Date, Event Type, Sub Event Type, Actors, Fatalities, Notes
      // UCDP: Event Date, Fatalities, Conflict Type, Place, Source
      bodyContent += `<ul style="${conflictListStyling} ${normalTextStyling}">`;
      bodyContent +=
        props.unix_timestamp_start_date && props.unix_timestamp_end_date
          ? getConflictDateRange(
              props.unix_timestamp_start_date,
              props.unix_timestamp_end_date
            )
          : `<li style="${normalTextStyling} color: #6f6f6f;">${translateFunc(
              "Unknown dates"
            )}</li>`;

      if (isTypeACLED) {
        bodyContent += `<li>${translateFunc("Event")}: ${
          props.event_type ?? translateFunc("Unknown")
        }${props.sub_event_type && "; " + props.sub_event_type}</li>`;
        bodyContent += `<li>${translateFunc("Actors")}: ${
          props.actor1 ?? translateFunc("Unknown")
        }${props.actor2 && "; " + props.actor2}</li>`;
        bodyContent += `<li>${translateFunc("Notes")}: ${props.notes}</li>`;
      } else {
        const low_high =
          props.low !== props.high ? props.low + " - " + props.high : props.low;
        bodyContent += "<li>" + props.conflict_name + "</li>";
        if (props.where_description) {
          bodyContent += "<li>" + props.where_description + "</li>";
        }
        bodyContent +=
          "<li>" +
          (low_high || translateFunc("Unknown")) +
          ` ${translateFunc("fatalities")}</li>`;
        bodyContent +=
          "<li>" +
          translateFunc("Source") +
          " - " +
          props.source_article +
          "</li>";
      }

      bodyContent += "</ul></div>";
    }
    content += bodyContent;
  } else {
    // Do region info popup content
    if (feature.state && feature.state.indicator) {
      const percentDiff = (feature.state.indicator * 100).toFixed(0);
      // Add CSS class for negative vs. positive to colorize green/red
      let indicatorClass = "positive";
      if (percentDiff < 0) {
        indicatorClass = "negative";
      }
      content += `<span class='indicator ${indicatorClass}'>
        <i class="arrow ${indicatorClass === "positive" ? "up" : "down"}"></i>
        ${percentDiff} %
    </span>`;

      if (feature.state.ymt !== undefined) {
        content +=
          `<br><span>${translateFunc("Month Average")}: ` +
          feature.state.ymt.toFixed(2) +
          "</span>" +
          `<br><span>${translateFunc("Historical Average")}: ` +
          feature.state.ht.toFixed(2) +
          "</span>" +
          `<br><span>${translateFunc("Variance")}: ` +
          feature.state.value.toFixed(2) +
          "</span>";
      }
    }
  }
  return content;
};

export default buildPopupHTMLContent;

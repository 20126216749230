import React from "react";
import PropTypes from "prop-types";
import { Form, Image } from "react-bootstrap";
import { HeaderSmall } from "./HeaderSmall";
import WaterSecurityLogo from "../assets/images/logo-mark-white.png";
import UNDPPALogo from "../assets/images/undppa-logo.png";

/**
 * displays floating container template
 * @param {string} [title=""] - displays title string in the header
 * @param {ReactElement|HTMLElement} [children=<Form/>] - displays content within the layout container
 * @returns {HTMLElement}
 */
const LayoutFloating = ({ title = "", children = <Form /> }) => (
  <main className="main-body">
    <section className="main-section rounded">
      <header className="text-center px-5">
        <Image className="px-5 py-3" src={UNDPPALogo} alt="UNDPPA Logo" fluid />
      </header>
      <HeaderSmall
        hideRightLink={true}
        title={title}
        logo={WaterSecurityLogo}
      />
      <section className="child-section rounded-bottom">{children}</section>
    </section>
  </main>
);

LayoutFloating.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};

export default LayoutFloating;

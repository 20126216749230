import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { save, load } from "redux-localstorage-simple";
import thunk from "redux-thunk";

import {
  TOGGLE_SIDEBAR_VISBILITY,
  TOGGLE_DARKMODE,
  TOGGLE_SELECTION_MODE,
  PRODUCTS_REQUEST,
  PRODUCTS_RECEIVE,
  PRODUCTS_TOGGLE,
  RESET_ACTIVE_INDICATORS,
  OVERLAYS_REQUEST,
  OVERLAYS_RECEIVE,
  OVERLAYS_TOGGLE,
  OVERLAYS_OPACITY_UPDATE,
  REGION_RECEIVE,
  REGION_REQUEST,
  TOGGLE_VISIBLE_TAB,
  LAYERS_REQUEST,
  SET_ACTIVE_LAYER,
  SET_ACTIVE_LAYER_GROUP,
  TIMESERIES_REQUEST,
  TIMESERIES_RECEIVE,
  TIMESERIES_FEATURE_REQUEST,
  TIMESERIES_FEATURE_RECEIVE,
  TOGGLE_FEATURE,
  UPDATE_FEATURE,
  GRAPH_SHOW_TOGGLE,
  GRAPH_EXPAND_TOGGLE,
  GRAPH_XAXIS_RANGE_SET,
  DATE_SET,
  ACCOUNT_LOGIN_REQUEST,
  ACCOUNT_LOGIN_RECEIVE,
  ACCOUNT_LOGOUT,
  ACCOUNT_REGISTER_REQUEST,
  ACCOUNT_REGISTER_RECEIVE,
  ACCOUNT_VERIFY_REQUEST,
  ACCOUNT_VERIFY_RECEIVE,
  CURRENT_ACCOUNT_REQUEST,
  CURRENT_ACCOUNT_RECEIVE,
  ACCOUNT_CHANGE_NAME_REQUEST,
  ACCOUNT_CHANGE_NAME_RECEIVE,
  ACCOUNT_CHANGE_EMAIL_RECEIVE,
  ACCOUNT_CHANGE_EMAIL_REQUEST,
  ACCOUNT_RESET_PASSWORD_REQUEST,
  ACCOUNT_RESET_PASSWORD_RECEIVE,
  ACCOUNT_SEND_RESET_PASSWORD_REQUEST,
  ACCOUNT_SEND_RESET_PASSWORD_RECEIVE,
  ACCOUNT_SEND_REGISTER_EMAIL_REQUEST,
  ACCOUNT_SEND_REGISTER_EMAIL_RECEIVE,
  USER_DISCLAIMER_AFFIRMATION_RECEIVE,
  USER_TUTORIAL_COMPLETION_RECEIVE,
  SET_MAP_LOADING,
} from "actions";

import {
  setDarkMode,
  setSidebarVisibility,
  productsUpdated,
  overlaysUpdated,
  updateOverlayOpacity,
  productsActive,
  setRegionData,
  timeseriesUpdated,
  timeseriesFeatureUpdated,
  toggleFeature,
  updateFeatureNames,
  graphExpand,
  graphShow,
  accountUpdate,
  setSelectionMode,
  toggleVisibleTab,
} from "reducers";
import { SelectionMode } from "./lib/constants";
import { overlaysToggle } from "./reducers";

export const initialState = {
  initTime: new Date(),
  isDarkMode: false,
  selectionMode: SelectionMode.MultiIndicators,
  current_mode: "value",
  layers: {
    loading: false,
    error: null,
    data: [],
  },
  layer_active: null,
  is_sidebar_visible: true,
  products: [],
  products_active: [],
  products_overlays: [],
  products_overlays_active: [],
  visible_tab: null,
  products_loading: true,
  overlays_loading: true,
  overlays_opacities: {},
  region_loading: false,
  region_data: {},
  features: {},
  features_active: {}, // key: layer name; value: feature id
  timeseries: [],
  timeseries_loading: false,
  timeseries_feature: [],
  timeseries_feature_loading: false,
  feature_id_hover: null,
  feature_name_hover: null,
  graph_show: false,
  graph_expand: false,
  graph_xaxis_range: null,
  date: new Date(),
  date_min: new Date(),
  date_max: new Date(),
  account_is_logged_in: false,
  account_user_id: null,
  account_email: null,
  account_first_name: "",
  account_last_name: "",
  account_jwt: null,
  account_jwt_refresh: null,
  account_login_response: null,
  account_register_response: null,
  account_verify_request_time: null,
  account_verify_response: null,
  account_change_name_response: null,
  account_change_email_response: null,
  account_reset_password_response: null,
  account_send_reset_password_response: null,
  account_send_register_email_response: null,
  user_disclaimer_affirmation: false,
  user_tutorial_completion: false,
  isMapLoading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_VISBILITY:
        return setSidebarVisibility(state, action);
    case TOGGLE_DARKMODE:
      return setDarkMode(state, action);
    case TOGGLE_SELECTION_MODE:
      return setSelectionMode(state, action);
    case PRODUCTS_REQUEST:
      return { ...state, products_loading: true };
    case PRODUCTS_RECEIVE:
      return productsUpdated(state, action);
    case PRODUCTS_TOGGLE:
      return productsActive(state, action);
    case RESET_ACTIVE_INDICATORS:
      return productsActive(state, action);
    case OVERLAYS_REQUEST:
      return overlaysUpdated(state, action);
    case OVERLAYS_RECEIVE:
      return overlaysUpdated(state, action);
    case OVERLAYS_TOGGLE:
      return overlaysToggle(state, action);
    case OVERLAYS_OPACITY_UPDATE:
      return updateOverlayOpacity(state, action);
    case REGION_REQUEST:
      return { ...state, products_loading: true };
    case REGION_RECEIVE:
      return setRegionData(state, action);
    case TOGGLE_VISIBLE_TAB:
      return toggleVisibleTab(state, action);
    case LAYERS_REQUEST:
      return {
        ...state,
        layers: {
          ...state.layers,
          ...action.payload,
        },
      };
    case SET_MAP_LOADING:
      return { ...state, isMapLoading: action.payload.isMapLoading };
    case SET_ACTIVE_LAYER:
      return { ...state, layer_active: action.payload.layer };
    case SET_ACTIVE_LAYER_GROUP:
      return { ...state, layer_group_active: action.payload.layerGroup };
    case TIMESERIES_REQUEST:
      return { ...state, timeseries_loading: true };
    case TIMESERIES_RECEIVE:
      return timeseriesUpdated(state, action);
    case TIMESERIES_FEATURE_REQUEST:
      return { ...state, timeseries_feature_loading: true };
    case TIMESERIES_FEATURE_RECEIVE:
      return timeseriesFeatureUpdated(state, action);
    case TOGGLE_FEATURE:
      return toggleFeature(state, action);
    case UPDATE_FEATURE:
      return updateFeatureNames(state, action);
    case GRAPH_EXPAND_TOGGLE:
      return graphExpand(state, action);
    case GRAPH_SHOW_TOGGLE:
      return graphShow(state, action);
    case GRAPH_XAXIS_RANGE_SET:
      return { ...state, graph_xaxis_range: action.range };
    case DATE_SET:
      return { ...state, date: action.date };
    case ACCOUNT_LOGIN_REQUEST:
    case ACCOUNT_LOGIN_RECEIVE:
    case ACCOUNT_LOGOUT:
    case ACCOUNT_REGISTER_REQUEST:
    case ACCOUNT_REGISTER_RECEIVE:
    case ACCOUNT_VERIFY_REQUEST:
    case ACCOUNT_VERIFY_RECEIVE:
    case CURRENT_ACCOUNT_REQUEST:
    case CURRENT_ACCOUNT_RECEIVE:
    case ACCOUNT_CHANGE_NAME_REQUEST:
    case ACCOUNT_CHANGE_NAME_RECEIVE:
    case ACCOUNT_CHANGE_EMAIL_REQUEST:
    case ACCOUNT_CHANGE_EMAIL_RECEIVE:
    case ACCOUNT_RESET_PASSWORD_REQUEST:
    case ACCOUNT_RESET_PASSWORD_RECEIVE:
    case ACCOUNT_SEND_RESET_PASSWORD_REQUEST:
    case ACCOUNT_SEND_RESET_PASSWORD_RECEIVE:
    case ACCOUNT_SEND_REGISTER_EMAIL_REQUEST:
    case ACCOUNT_SEND_REGISTER_EMAIL_RECEIVE:
    case USER_DISCLAIMER_AFFIRMATION_RECEIVE:
    case USER_TUTORIAL_COMPLETION_RECEIVE:
      return accountUpdate(state, action);
    default:
      return state;
  }
};

const Store = () => {
  // Set global variable, to be used by ajax functions
  const windowGlobal = typeof window !== "undefined" && window;
  let state = initialState;
  if (windowGlobal) {
    // spreading is more reliable than preloadedState argument (?)
    state = { ...initialState, ...load() };
    // migrate features_active from array to object
    // might still require the user to refresh
    // after some time, this could be removed
    if (state.features_active instanceof Array) {
      state.features_active = {
        [state.layer_active?.name]: state.features_active,
      };
    }
    windowGlobal.ACCOUNT_JWT = state.account_jwt;
    windowGlobal.ACCOUNT_JWT_REFRESH = state.account_jwt_refresh;
  }
  // Patch the date because localStorage converts dates to strings
  state.date = new Date(state.date);
  state.date_min = new Date(state.date_min);
  state.date_max = new Date(state.date_max);

  const composeEnhancers =
    (process.env.NODE_ENV === "development" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  return createStore(
    reducer,
    state,
    composeEnhancers(applyMiddleware(thunk, save()))
  );
};

export const wrapper = ({ element }) => (
  <Provider store={Store()}>{element}</Provider>
);

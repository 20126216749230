module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-react-i18next-virtual-5d50a2c4b3/3/.yarn/berry/cache/gatsby-plugin-react-i18next-npm-1.2.3-ed7df1060f-10c0.zip/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","ar","fr"],"defaultLanguage":"en","siteUrl":"https://dprk.geoguard.org/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-96c26ffae4/3/.yarn/berry/cache/gatsby-plugin-manifest-npm-3.15.0-6c95d236f5-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Geoguard","short_name":"geoguard","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/logo-mark.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"98a5dcec3cf69a5d7fc9a58d602eb6f7"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-8da891301f/3/.yarn/berry/cache/gatsby-plugin-google-gtag-npm-5.14.0-7fc489a55d-10c0.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WC0MBJDD00"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-use-query-params-virtual-aad6f28e35/3/.yarn/berry/cache/gatsby-plugin-use-query-params-npm-1.0.1-878a7051ae-10c0.zip/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import React from "react";
import PropTypes from "prop-types";
import { Navbar, Nav } from "react-bootstrap";
import AppLogo from "../assets/images/logo-mark-white.png";
import NavLogo from "./NavLogo";
import LanguageSelector from "./LanguageSelector";

/**
 * shows the header component
 * @param {string} [siteTitle=""] - site title
 * @param {string} [siteDescription=""] - site description
 * @param {Element} children - children for component composition
 * @param {bool} [fadeIn=false] - sets transparency transition on mouse in/out
 * @returns {any}
 */
const Header = ({
  siteTitle = "",
  siteDescription = "",
  children,
  fadeIn = false,
}) => {
  return (
    <header className={`main-header ${fadeIn ? "header-fade" : ""}`}>
      <Navbar variant="dark" className="p-0">
        <Nav className="w-100 d-block">
          <Navbar.Brand className="d-block text-center">
            {siteTitle}
          </Navbar.Brand>
        </Nav>
        <NavLogo
          appLogo={AppLogo}
          logoLinkPath="/"
          logoAltText={siteDescription}
          rtl={true}
        >
          <>
            {children}
            <LanguageSelector />
          </>
        </NavLogo>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  children: PropTypes.element,
  fadeIn: PropTypes.bool,
};

export default Header;

import { initialState } from "state";

import {
  TOGGLE_SIDEBAR_VISBILITY,
  TOGGLE_DARKMODE,
  TOGGLE_SELECTION_MODE,
  PRODUCTS_REQUEST,
  PRODUCTS_RECEIVE,
  OVERLAYS_REQUEST,
  OVERLAYS_RECEIVE,
  OVERLAYS_TOGGLE,
  OVERLAYS_OPACITY_UPDATE,
  PRODUCTS_TOGGLE,
  RESET_ACTIVE_INDICATORS,
  REGION_REQUEST,
  REGION_RECEIVE,
  TOGGLE_VISIBLE_TAB,
  TIMESERIES_RECEIVE,
  TIMESERIES_FEATURE_RECEIVE,
  TOGGLE_FEATURE,
  UPDATE_FEATURE,
  GRAPH_SHOW_TOGGLE,
  GRAPH_EXPAND_TOGGLE,
  ACCOUNT_LOGIN_REQUEST,
  ACCOUNT_LOGIN_RECEIVE,
  ACCOUNT_LOGOUT,
  ACCOUNT_REGISTER_REQUEST,
  ACCOUNT_REGISTER_RECEIVE,
  ACCOUNT_VERIFY_REQUEST,
  ACCOUNT_VERIFY_RECEIVE,
  CURRENT_ACCOUNT_RECEIVE,
  CURRENT_ACCOUNT_REQUEST,
  ACCOUNT_CHANGE_NAME_RECEIVE,
  ACCOUNT_SEND_RESET_PASSWORD_RECEIVE,
  ACCOUNT_CHANGE_EMAIL_RECEIVE,
  ACCOUNT_RESET_PASSWORD_RECEIVE,
  ACCOUNT_SEND_REGISTER_EMAIL_RECEIVE,
  USER_DISCLAIMER_AFFIRMATION_RECEIVE,
  USER_TUTORIAL_COMPLETION_RECEIVE,
} from "actions";

import { COMPOSITE, SelectionMode } from "lib/constants";
import { getAccountFromToken } from "lib/util";
import parseTimestamp from "./lib/parseTimestamp";
import getCommonMaxDateFromProducts from "./lib/getCommonMaxDateFromProducts";

export const setDarkMode = (state = { isDarkMode: false }, action) => {
  switch (action.type) {
    case TOGGLE_DARKMODE:
      return { ...state, isDarkMode: action.isDarkMode };
    default:
      return state;
  }
};

export const setSidebarVisibility = (state = { is_sidebar_visible: false }, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_VISBILITY:
      return { ...state, is_sidebar_visible: action.isSidebarVisible };
    default:
      return state;
  }
};

export const setSelectionMode = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_SELECTION_MODE:
      return {
        ...state,
        selectionMode: action.selectionMode,
        products_active: initialState.products_active,
        visible_tab: initialState.visible_tab,
        features_active: initialState.features_active,
        graph_show: false,
      };
    default:
      return state;
  }
};

export const setRegionData = (state = {}, action) => {
  switch (action.type) {
    case REGION_REQUEST:
      return { ...state, region_loading: true };
    case REGION_RECEIVE:
      return {
        ...state,
        region_loading: false,
        region_data: action.payload,
      };
    default:
      return state;
  }
};

export const productsUpdated = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return { ...state, products_loading: true };
    case PRODUCTS_RECEIVE:
      return {
        ...state,
        products_loading: false,
        products: action.payload,
        // products_active: state.products_active
      };
    default:
      return state;
  }
};

export const productsActive = (state = [], action) => {
  switch (action.type) {
    case RESET_ACTIVE_INDICATORS:
      return {
        ...state,
        products_active: initialState.products_active,
        visible_tab: initialState.visible_tab,
        features_active: initialState.features_active,
        date: getCommonMaxDateFromProducts(state.products),
        date_min: initialState.date_min,
        date_max: initialState.date_max,
        graph_show: false,
      };
    case PRODUCTS_TOGGLE:
      // Handles indicator or feature toggle
      if (action.payload && action.payload.name) {
        const clickedProduct = action.payload.name;
        const oldFeaturesActiveLayer =
          state.features_active[state.layer_active?.name] ?? [];

        let productsSet = new Set(state.products_active);
        let newVisibleTab = state.visible_tab;
        let newFeatures = { ...state.features_active };

        switch (state.selectionMode) {
          case SelectionMode.MultiIndicators:
            newFeatures = {
              [state.layer_active?.name]: oldFeaturesActiveLayer.slice(-1),
            };
            if (productsSet.has(clickedProduct)) {
              productsSet.delete(clickedProduct);
              // Remove composite if now returning to 1 indicator.
              if (productsSet.has(COMPOSITE) && productsSet.size === 2) {
                productsSet.delete(COMPOSITE);
              }
            } else {
              productsSet.add(clickedProduct);
              if (productsSet.size > 1) {
                productsSet.add(COMPOSITE);
              }
            }
            if (productsSet.has(COMPOSITE)) {
              newVisibleTab = COMPOSITE;
            } else if (!productsSet.has(state.visible_tab)) {
              newVisibleTab = Array.from(productsSet)[0];
            }
            break;
          case SelectionMode.MultiRegions:
            productsSet.clear();
            productsSet.add(clickedProduct);
            break;
          default:
            productsSet.clear();
            productsSet.add(clickedProduct);
            newFeatures = {
              [state.layer_active?.name]: oldFeaturesActiveLayer.slice(-1),
            };
        }

        let dateMax = null;
        let dateMin = null;
        state.products.forEach((product) => {
          if (productsSet.has(product.name)) {
            if (dateMax === null) {
              dateMax = parseTimestamp(product.date_max);
            } else {
              dateMax = new Date(
                Math.min(dateMax, parseTimestamp(product.date_max))
              );
            }

            if (dateMin === null) {
              dateMin = parseTimestamp(product.date_min);
            } else {
              dateMin = new Date(
                Math.max(dateMin, parseTimestamp(product.date_min))
              );
            }
          }
        });

        return {
          ...state,
          products_active: Array.from(productsSet),
          visible_tab: newVisibleTab,
          features_active: newFeatures,
          date_min: dateMin || new Date(),
          date_max: dateMax || new Date(),
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export const overlaysUpdated = (state = {}, action) => {
  switch (action.type) {
    case OVERLAYS_REQUEST:
      return { ...state, overlays_loading: true };
    case OVERLAYS_RECEIVE:
      return {
        ...state,
        overlays_loading: false,
        products_overlays: action.payload,
      };
    default:
      return state;
  }
};

export const updateOverlayOpacity = (state = {}, action) => {
  switch (action.type) {
    case OVERLAYS_OPACITY_UPDATE:
      return {
        ...state,
        overlays_opacities: {
          ...state.overlays_opacities,
          [action.payload.name]: action.payload.opacity,
        },
      };
    default:
      return state;
  }
};

export const overlaysToggle = (state = {}, action) => {
  switch (action.type) {
    case OVERLAYS_TOGGLE:
      if (action.payload.name === undefined) {
        return {
          ...state,
          products_overlays_active: [],
        };
      }
      return {
        ...state,
        products_overlays_active: [action.payload.name],
      };
  }
};

export const toggleVisibleTab = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_VISIBLE_TAB:
      return {
        ...state,
        visible_tab: action.name,
      };
    default:
      return state;
  }
};

export const timeseriesUpdated = (state = {}, action) => {
  switch (action.type) {
    case TIMESERIES_RECEIVE:
      return {
        ...state,
        timeseries: action.payload,
        timeseries_loading: false,
      };
    default:
      return state;
  }
};

export const timeseriesFeatureUpdated = (state = {}, action) => {
  switch (action.type) {
    case TIMESERIES_FEATURE_RECEIVE:
      return {
        ...state,
        timeseries_feature: action.payload,
        timeseries_feature_loading: false,
      };
    default:
      return state;
  }
};

export const toggleFeature = (state = {}, action) => {
  const fprops = action.payload.properties;

  switch (action.type) {
    case TOGGLE_FEATURE:
      if (state.selectionMode === SelectionMode.MultiRegions) {
        const clickedFeature = action.payload.feature_id;

        let old_layer_features =
          state.features_active[state.layer_active?.name] ?? [];
        let new_layer_features;
        if (old_layer_features.includes(clickedFeature)) {
          if (old_layer_features.length === 1) {
            // noop: can't go to 0 regions.
            new_layer_features = [...old_layer_features];
          } else {
            new_layer_features = old_layer_features.filter(
              (feat) => feat !== clickedFeature
            );
          }
        } else {
          new_layer_features = [...old_layer_features, clickedFeature];
        }
        // Remove composite if now returning to 1 indicator.
        if (
          new_layer_features.includes(COMPOSITE) &&
          new_layer_features.filter((f) => f !== COMPOSITE).length === 1
        ) {
          new_layer_features = new_layer_features.filter(
            (f) => f !== COMPOSITE
          );
        }

        return {
          ...state,
          features: { ...state.features, [clickedFeature]: fprops },
          features_active: {
            ...state.features_active,
            [state.layer_active?.name]: new_layer_features,
          },
          products_active: state.products_active
            .filter((p) => p !== COMPOSITE)
            .slice(-1),
          visible_tab: new_layer_features.includes(clickedFeature)
            ? clickedFeature
            : new_layer_features[0],
        };
      }

      if (state.selectionMode === SelectionMode.Default) {
        return {
          ...state,
          features: { ...state.features, [action.payload.feature_id]: fprops },
          features_active: {
            [state.layer_active?.name]: [action.payload.feature_id],
          },
          products_active: state.products_active
            .filter((p) => p !== COMPOSITE)
            .slice(-1),
        };
      }

      return {
        ...state,
        features: { ...state.features, [action.payload.feature_id]: fprops },
        features_active: {
          [state.layer_active?.name]: [action.payload.feature_id],
        },
      };
    default:
      return state;
  }
};

// Features state is derived from Map Features properties
// On language change region labels are updated with translations in response,
// so we need to update state as well to update other references in app
export const updateFeatureNames = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FEATURE:
      return {
        ...state,
        features: {
          ...state.features,
          [action.payload.feature_id]: action.payload.properties,
        },
      };
    default:
      return state;
  }
};

export const graphExpand = (state = { graph_expand: false }, action) => {
  switch (action.type) {
    case GRAPH_EXPAND_TOGGLE:
      return {
        ...state,
        graph_expand:
          action.graph_expand === null
            ? !state.graph_expand
            : action.graph_expand,
      };
    default:
      return state;
  }
};

export const graphShow = (state = { graph_show: false }, action) => {
  switch (action.type) {
    case GRAPH_SHOW_TOGGLE:
      state = {
        ...state,
        graph_show:
          action.graph_show === null ? !state.graph_show : action.graph_show,
      };
      return state;
    default:
      return state;
  }
};

const defaultAccountState = {
  account_is_logged_in: false,
  account_email: null,
  account_user_id: null,
  account_first_name: "",
  account_last_name: "",
  account_jwt: null,
  account_jwt_refresh: null,
  account_login_response: null,
  account_change_name_response: null,
  account_verify_user_request_time: null,
  account_verify_user_response: null,
};

export const accountUpdate = (state = defaultAccountState, action) => {
  switch (action.type) {
    case CURRENT_ACCOUNT_REQUEST:
    case CURRENT_ACCOUNT_RECEIVE:
      // here we save the requesters first and last name into the state
      if (
        action.payload &&
        action.payload.first_name &&
        action.payload.last_name
      ) {
        return {
          ...state,
          account_email: action.payload.email,
          account_first_name: action.payload.first_name,
          account_last_name: action.payload.last_name,
        };
      }
      return state;
    case ACCOUNT_LOGIN_REQUEST:
      // Here we save the requesters email which we use after logging in
      if (action.email) {
        return {
          ...state,
          account_email: action.email,
          account_login_response: null,
        };
      }
      return state;
    case ACCOUNT_LOGIN_RECEIVE:
      if (action.payload.success) {
        const { access, refresh } = action.payload.data;

        const { user_id } = getAccountFromToken(access);

        window.USER_DISCLAIMER_AFFIRMATION = undefined;
        window.ACCOUNT_JWT = access;

        if (refresh) {
          window.ACCOUNT_JWT_REFRESH = refresh;
        }

        return {
          ...state,
          account_user_id: user_id,
          account_is_logged_in: Boolean(access),
          account_jwt: access,
          account_jwt_refresh: refresh ? refresh : state.account_jwt_refresh,
          account_login_response: null,
        };
      }
      return { ...state, account_login_response: action.payload };
    case ACCOUNT_LOGOUT:
      window.ACCOUNT_JWT = undefined;
      window.ACCOUNT_JWT_REFRESH = undefined;
      window.USER_DISCLAIMER_AFFIRMATION = undefined;

      return {
        ...state,
        ...initialState,
      };
    case ACCOUNT_REGISTER_REQUEST:
      if (action.email) {
        return { ...state, account_email: action.email };
      }
      return state;
    case ACCOUNT_REGISTER_RECEIVE:
      return { ...state, account_register_response: action.payload };
    case ACCOUNT_VERIFY_REQUEST:
      return { ...state, account_verify_request_time: action.time };
    case ACCOUNT_VERIFY_RECEIVE:
      return { ...state, account_verify_response: action.payload };
    case ACCOUNT_CHANGE_NAME_RECEIVE:
      if (action.payload) {
        let newFirstName = "";
        let newLastName = "";

        if (action.payload.data) {
          newFirstName = action.payload.data.first_name;
          newLastName = action.payload.data.last_name;
        }

        return {
          ...state,
          account_first_name: newFirstName,
          account_last_name: newLastName,
          account_change_name_response: action.payload,
        };
      }

      return {
        ...state,
        account_change_name_response: null,
      };
    case ACCOUNT_CHANGE_EMAIL_RECEIVE:
      if (action.payload) {
        return {
          ...state,
          account_confirm_register_email_response: action.payload,
        };
      }

      return {
        ...state,
        account_confirm_register_email_response: null,
      };
    case ACCOUNT_RESET_PASSWORD_RECEIVE:
      if (action.payload) {
        return {
          ...state,
          account_reset_password_response: action.payload,
        };
      }

      return {
        ...state,
        account_reset_password_response: null,
      };
    case ACCOUNT_SEND_RESET_PASSWORD_RECEIVE:
      return {
        ...state,
        account_send_reset_password_response: action.payload,
      };
    case ACCOUNT_SEND_REGISTER_EMAIL_RECEIVE:
      return {
        ...state,
        account_send_register_email_response: action.payload,
      };
    case USER_DISCLAIMER_AFFIRMATION_RECEIVE:
      return {
        ...state,
        user_disclaimer_affirmation: action.affirm,
      };
    case USER_TUTORIAL_COMPLETION_RECEIVE:
      return {
        ...state,
        user_tutorial_completion: action.completion,
      };
    default:
      return state;
  }
};
